;(function($, window) {

  var App = {};

  // App.breakpoints = {
  //  xs : 480,
  //  sm: 768,
  //  md: 992,
  //  md2: 1260,
  //  lg: 1460,
  //  xl: 1620
  // };

  // App.gridGutterWidth = 30;
  App.isMobile = $('html').hasClass('mobile');
  App.isTablet = $('html').hasClass('tablet');
  App.isDesktop = $('html').hasClass('desktop');


  var BEHAVIOUR_PREFIX = "js",
    BEHAVIOUR = {},
    GLOBAL_BEHAVIOURS = [];


  BEHAVIOUR.addToCart = function() {

    var _this = $(this);

    function init() {

      if (_this.data('__addToCart'))
        return;
      _this.data('__addToCart',true);

      if(!_this.data('action'))
        return;

      _this.loading = false;

      _this.$modalSuccess = $('#modal-checkout-cart-add-success');
      _this.$modalError = $('#modal-checkout-cart-add-error');

      _this.on('submit', function(event) {
        event.preventDefault();

        if(_this.loading)
          return;

        _this.loading = true;

        $.ajax({
          url: _this.data('action'),
          method: _this.attr('method'),
          dataType: 'json',
          data: _this.serialize(),
          xhrFields: {
            withCredentials: true
          },
          success: function(r) {
            if(r.success) {
              _this.$modalSuccess.find('.modal-body')
                .empty()
                .html('<p>'+r.success+'</p>');
              updateCartInformations(r);
              _this.$modalSuccess.modal('show');
            } else {

              _this.$modalError.find('.modal-body').empty();

              var errors = [];
              $.each(r.error, function(index, value) {
                if(typeof(value) == "string") {
                   errors.push(value);
                } else {
                  $.each(value, function(index, value) {
                    if(typeof(value) == "string")
                      errors.push(value);
                  });
                }
              });

              $.each(errors, function(index, value) {
                _this.$modalError.find('.modal-body').append('<p>' + value + '</p>');
              });

              _this.$modalError.modal('show');
            }
          },
          complete : function() {
            _this.loading = false;
          }
        });
      });
    }

    function updateCartInformations(r) {
      var total = (r.total > 0) ? r.total : "";
      var price = r.price;
      $('.minicart__total, .main-nav__cart-total').text(total);
      $('.minicart__price').text(price);
      $(window).resize();
    }

    init();
    return this;
  };

  BEHAVIOUR.breadcrumbNav = function() {

    var _this = $(this);

    function init() {

      if (_this.data('__breadcrumbNav'))
        return;
      _this.data('__breadcrumbNav',true);

      _this.$container = _this.parent();
      _this.$siblingButton = _this.$container.find('.tool-nav__cart');
      _this.logoWidth = 50;
      _this.$listItems = _this.find('.breadcrumb-nav__list-item');
      _this.collapsedItems = 0;

      _this.$container.on('mouseleave', function() {
        // hideMenus( _this.$listItems.not($item));
        _this.find('.is-revealed').removeClass('is-revealed');
      });

      if(App.isDesktop) {

        _this.$listItems.hover(function() {
          var $item = $(this);
          hideMenus( _this.$listItems.not($item));
          showMenu($item);
        }, function() {
          hideMenus(_this.$listItems);
        });

      } else {

        _this.$listItems.click(function(event) {
          var $el = $(this);
          if($el.hasClass('has-childs') && !$el.hasClass('is-active')) {
            event.preventDefault();
            hideMenus(_this.$listItems.not($el));
            showMenu($el);
          }
        });
      }

      _this.on('touchstart.breadcrumbNav click.breadcrumbNav', function(event) {
        event.stopPropagation();
      });

      $('body').on('touchstart.breadcrumbNav click.breadcrumbNav', function() {
        hideMenus(_this.$listItems);
        _this.find('.is-revealed').removeClass('is-revealed');
      });


      $(window).on('resize', function() {
        hideNav();
        resetBreadcrumbs();
        collapseLoop();
        showNav();
      });

      collapseLoop();
      showNav();

    }

    function hideMenus($el) {
      $el.each(function() {
        var $dropdown = $(this).find('.breadcrumb-nav__dropdown');
        $(this).removeClass('is-active');
        $dropdown.removeClass('is-open'); //.css({'height': 0, 'width' : 0});
      });
    }

    function showMenu($el) {
      $el.each(function() {
        $(this).addClass('is-active').addClass('is-revealed');
        var $dropdown = $(this).find('.breadcrumb-nav__dropdown');
        var $menu = $dropdown.find('.breadcrumb-nav__dropdown-inner');
        $dropdown.addClass('is-open'); //.css({'width': $menu.width(), 'height': $menu.height()});
      });
    }

    function getAvailableWidth() {
      return _this.$container.width() - _this.$siblingButton.width() - _this.logoWidth - 30;
    }

    function isSpaceEnough() {
      return _this.width() < getAvailableWidth();
    }

    function resetBreadcrumbs() {
      _this.$listItems.removeClass('is-collapsed');
    }

    function collapseLoop(items) {
      if(items === undefined)
        items = 1;

      if(items==_this.$listItems.length)
        return;

      if(!isSpaceEnough()) {
        collapseChilds(items);
      }
    }

    function collapseChilds(items) {
      resetBreadcrumbs();
      _this.$listItems.filter(function(index) {
        return index < items;
      }).addClass('is-collapsed');
      collapseLoop(items+1);
    }

    function hideNav() {
      _this.removeClass('is-ready');
    }

    function showNav() {
      _this.addClass('is-ready');
    }



    init();
    return this;
  };

  BEHAVIOUR.contentBlockGallery = function() {

    var _this = $(this);

    function init() {

      if (_this.data('__contentBlockGallery'))
        return;
      _this.data('__contentBlockGallery',true);

      var swiper = new Swiper(_this.find('.swiper-container'), {
        autoplay: 5000,
        spaceBetween: 15,
        pagination: '.swiper-pagination',
        nextButton: '.swiper-button-next',
        prevButton: '.swiper-button-prev',
        loop: true,
        paginationClickable: true
      });
    }

    init();
    return this;
  };

  BEHAVIOUR.contactForm = function() {

    var _this = $(this);

    function init() {

      if (_this.data('__contactForm'))
        return;
      _this.data('__contactForm',true);

      if(!App.isDesktop) {

        _this.find('.contact-form__input--select').each(function() {
          var $this = $(this);
          var $listItems = $this.find('li');
          var $select = $('<select></select>');
          $listItems.each(function() {
            var $option = $('<option>' + $(this).text() + '</option>');
            $option.appendTo($select);
          });
          $select.appendTo($this);
        });

        _this.find('.contact-form__input--select select').on('change', function(event) {
          event.preventDefault();
          event.stopPropagation();
          var $this = $(this);
          var $parent = $this.closest('.contact-form__input--select');
          $parent.find('input').val($this.val());
        });

      } else {

        _this.find('.contact-form__input--select').on('click', function(event) {
          event.preventDefault();
          var $this = $(this);
          if(!$this.data('open')) {
            showSelect($this);
          }
        });

        _this.find('.contact-form__input--select input').on('focus click', function(event) {
          event.preventDefault();
          var $this = $(this).parent();
          if(!$this.data('open')) {
            showSelect($this);
          }
        });

        _this.find('.contact-form__input--select li').on('click', function(event) {
          event.preventDefault();
          event.stopPropagation();
          var $this = $(this);
          var $parent = $this.closest('.contact-form__input--select');
          if($parent.data('open')) {
            $parent.find('input').val($this.text());
            $parent.find('li').removeClass('is-active');
            $this.addClass('is-active');
            hideSelect($parent);
          }
        });

      }

      _this.find('form').on('submit', function(event) {
        event.preventDefault();

        var error = false;
        var errorClass = "has-error";

        _this.find('.' + errorClass).removeClass(errorClass);
        _this.find('.alert').hide();

        var $name = _this.find('input[name="name"]');
        var $subject = _this.find('input[name="subject"]');
        var $email = _this.find('input[name="email"]');
        var $telephone = _this.find('input[name="telephone"]');

        var name = $.trim($name.val());
        var subject = $.trim($subject.val());
        var email = $.trim($email.val());
        var telephone = $.trim($telephone.val());

        if(!name) {
          error = true;
          $name.parent().addClass(errorClass);
        }

        if(!subject) {
          error = true;
          $subject.parent().addClass(errorClass);
        }

        if(!email) {
          error = true;
          $email.parent().addClass(errorClass);
        }

        if(!telephone) {
          error = true;
          $telephone.parent().addClass(errorClass);
        }

        if(error) {
          // _this.find('.' + errorClass+ ' input').first().focus();
          _this.find('.alert--mandatory').fadeIn();
          return;
        }

        if(getLoadingStatus())
          return;

        setLoadingStatus(true);

        _this.find('.alert').hide();

        var $form = _this.find('form');
        var data = $form.serialize();
        var url = $form.data('action');

        $.ajax({
          url : url,
          data : data,
          method: 'post',
          dataType : 'json',
          success: function(r) {
            if(r.status == "success") {
              if(_this.find('input[name="redirect"]')) {
                window.location.href = _this.find('input[name="redirect"]').val();
              } else {
                _this.find('.alert-success').fadeIn();
                _this.find('button').hide();
              }
            }
            if(r.status == "error") {
              _this.find('.alert--error').fadeIn();
            }
            if(r.status == "mandatory") {
              _this.find('.alert--mandatory').fadeIn();
            }
            setLoadingStatus(false);
          }
        });

      });


    }

    function showSelect($element) {
        $element.data('open', true).addClass('is-open');
    }

    function hideSelect($element) {
        $element.data('open', false).removeClass('is-open');
    }

    function setLoadingStatus(status) {
      _this.loading = status;
      var $btn = _this.find('button[type="submit"]');
      if(status) {
        $btn.addClass('btn-disabled').attr('disabled', 'disabled');
      } else {
        $btn.removeClass('btn-disabled').removeAttr('disabled');
      }
    }

    function getLoadingStatus() {
      return _this.loading;
    }

    init();
    return this;
  };

  BEHAVIOUR.cookieAlert = function() {

    var _this = $(this);

    _this.initialized = false;

    _this.cookie = {
      name: 'biotek-cookie-alert',
      value: '1',
      days: '730'
    };

    function init() {

      if (_this.data('__cookieAlert'))
        return;
      _this.data('__cookieAlert',true);

      checkStatus();
    }

    function ready() {
      _this.find('[data-cookie-dismiss]').on('click', onClickDismiss);
      // _this.find('[data-cookie-url]').on('click', onClickUrl);
      show();
    }

    function show() {
      _this.addClass('is-ready').height();
      _this.addClass('is-visible');
    }

    function hide() {
      _this.removeClass('is-visible');
    }

    function checkStatus() {
      if (getCookie(_this.cookie.name) != _this.cookie.value) {
        ready();
      }
    }

    function onClickDismiss(event) {
      setCookie(_this.cookie);
      hide();
    }

    function onClickUrl(event) {
    }

    function setCookie(options) {
      var d = new Date();
      d.setTime(d.getTime() + (options.days * (24*60*60*1000)));
      document.cookie = options.name +'='+ options.value +'; expires='+ d.toUTCString() +'; domain=' + getDomain() + '; path=/';
    }

    function getCookie(name) {
      var ca = document.cookie.split(';');
      name = name + '=';
      for(var i=0; i<ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1);
        if (c.indexOf(name) === 0) return c.substring(name.length, c.length);
      }
      return undefined;
    }

    function getDomain() {
      var domain = location.hostname;
      cookieDomain = domain;
      if(domain.indexOf('.') != -1) { // to make it works on localhost too
        var parts = domain.split('.');
        cookieDomain = parts[parts.length-2] +'.'+ parts[parts.length-1];
      }
      return cookieDomain;
    }

    init();
    return this;
  };

  BEHAVIOUR.coverList = function() {

    var _this = $(this);

    function init() {

      if (_this.data('__coverList'))
        return;
      _this.data('__coverList', true);

      _this.coversLenght = _this.find('.cover').length;

      TweenMax.to(_this.find('.cover-list__scroll'), 0.5, {y:0, opacity: 1, ease: Power2.easeOut, delay: 1, onStart: function() {
        if(_this.hideScroll)
          TweenMax.to(_this.find('.cover-list__scroll'), 0.5, {opacity: 0});
      }});

      // create pagination
      if(_this.coversLenght>1) {
        for (var i=0; i < _this.coversLenght; i++) {
          _this.find('.swiper-pagination').append('<div class="swiper-pagination-bullet"></div>');
        }
      }

      _this.find('.swiper-pagination-bullet')
        .click(function() {
          scrollTo($(this).index());
        })
        .first()
        .addClass('swiper-pagination-bullet-active');

      // pagination and cover's content waypoints
      _this.find('.cover').waypoint({
        handler: function(direction) {

          var $element = $(this.element);
          var index = $element.index();
          var activeIndex;

          if(direction=="up") {
            activeIndex = index-1;
          } else {
            activeIndex = index;
          }

          setPagination(activeIndex);

          if(!$element.data('init')) {
            $element.data('init', true);
            var $childs = $element.find('.cover__title, .cover__subtitle');
            TweenMax.staggerFromTo($childs, 0.6, {y: 50, opacity: 0}, {y: 0, opacity: 1, ease: Power2.easeOut}, 0.3);
            TweenMax.fromTo($element.find('.cover__spacer'), 0.6, {scaleX:0, opacity: 0}, {scaleX:1, opacity: 1, delay: 0.2});
          }

        },
        offset: "40%"
      });

      // hide "scroll" text when it's not necessary
      _this.find('.cover:nth-child(1)').waypoint({
        handler: function(direction) {
          TweenMax.to(_this.find('.cover-list__scroll'), 0.5, {opacity: 0});
          _this.hideScroll = true;
          this.destroy();
        },
        offset: "-5%"
      });

      // hide "pagination" when it's reaching the end
      _this.waypoint({
        handler: function(direction) {
          if(direction=="up") {
            _this.removeClass('pagination-out');
          } else {
            _this.addClass('pagination-out');
          }
        },
        offset: 'bottom-in-view'
      });

    }

    function setPagination(index) {
      _this.find('.swiper-pagination-bullet')
        .removeClass('swiper-pagination-bullet-active')
        .eq(index)
        .addClass('swiper-pagination-bullet-active');
    }

    function scrollTo(index) {
      var scrollTo = _this.find('.cover:nth-child('+ (index+1) +')').offset().top;
      TweenMax.to(window, 0.7, {scrollTo:scrollTo, ease: Power3.easeInOut});

    }

    init();
    return this;
  };

  BEHAVIOUR.customDropdown = function() {

    var _this = $(this);

    function init() {

      if (_this.data('__customDropdown'))
        return;
      _this.data('__customDropdown', true);

      _this.$toggle = _this.find('> a');

      if(App.isDesktop) {

        _this.hover(function() {
          _this.addClass('is-open');
        }, function() {
          _this.removeClass('is-open');
        });

      } else {

        _this.$toggle.click(function(event) {
          var $el = $(this);
          _this.toggleClass('is-open');
        });
      }

      _this.on('touchstart.customdropdown click.customdropdown', function(event) {
        event.stopPropagation();
      });

      $('body').on('touchstart.customdropdown click.customdropdown', function() {
        _this.removeClass('is-open');
      });

    }

    init();
    return this;
  };

  BEHAVIOUR.fancyboxVideo = function() {

    var _this = $(this);

    function init() {

      if (_this.data('__fancyboxVideo'))
        return;
      _this.data('__fancyboxVideo',true);

      var initialized = false,
        $html = $('html'),
        isMobile = $html.hasClass('mobile'),
        isTablet = $html.hasClass('tablet'),
        isDesktop = !isMobile && !isTablet,
        margin;

      margin = [90, 30, 90, 30];
      if(isMobile)
        margin = [60, 0, 60, 0];

      $('body').keyup(function(e){
          if(e.keyCode == 27)
            $.fancybox.close();
      });

      _this.click(function(event) {
        event.preventDefault();
        $.fancybox.open([
            {content : ' '},
            {href : _this.attr('href')},
          ],{
          aspectRatio : true,
          autoSize: false,
          width: 1600 * 2,
          height: 900 * 2,
          closeBtn: false,
          keys: {
            next : null,
            prev : null,
            play : null,
          },
          margin: margin,
          padding: 0,
          maxWidth: '100%',
          maxHeight: '100%',
          scrolling: false,
          mouseWheel : false,
          arrows: false,
          nextEffect : 'none',
          prevEffect : 'none',
          openEffect  : 'none',
          closeEffect : 'fade',
          openSpeed : 10,
          closeSpeed : 250,
          helpers : {
            media : true,
          },
          beforeShow : function() {
          },
          afterShow: function() {
            if(!initialized) {
              $('<div class="fancybox-video-close-button close-fancybox"><span class="icon-cross"></span></div>')
                .appendTo('body')
                .click(function() {
                  $.fancybox.close();
                });
              $('body').addClass('fancybox-transition');
              $.fancybox.next();
            }
            initialized = true;
          },
          beforeClose: function() {
            $('body').removeClass('fancybox-transition');
          },
          afterClose: function() {
            initialized = false;
            $('.close-fancybox').remove();
          }
        });
      });
    }

    init();
    return this;
  };

  BEHAVIOUR.faq = function() {

    var _this = $(this);

    function init() {

      if (_this.data('__faq'))
        return;
      _this.data('__faq',true);

      _this.find('[data-toggle]').click(function(event) {
        event.preventDefault();
        _this.toggleClass('is-open');
        _this.find('[data-collapse]').collapse('toggle');
      });

    }

    init();
    return this;
  };

  BEHAVIOUR.fixedNav = function() {

    // require waypoints

    var _this = $(this);

    function init() {

      if (_this.data('__fixedNav'))
        return;
      _this.data('__fixedNav',true);

      _this.waypoint({
        handler: function(direction) {
          if(direction=="down") {
            $(this.element).addClass('is-bottom');
          } else {
            $(this.element).removeClass('is-bottom');
          }
        },
        offset: function() {
          var height = _this.height();
          var headerHeight = _this.find('.fixedNav-header').height();
          return headerHeight - height;
        }
      });

    }

    init();
    return this;
  };

  BEHAVIOUR.formOperator = function() {

    var _this = $(this);

    function init() {

      if (_this.data('__formOperator'))
        return;
      _this.data('__formOperator',true);

      _this.loading = false;

      var locale = 'en_US';

      var lang = $('html').attr('lang');

      if(lang == "it")
        locale = "it_IT";
      if(lang == "ru")
        locale = "ru_RU";

      _this.formValidation({
        locale: locale
      })
        .on('success.form.fv', function(e) {
          e.preventDefault();

          if(getLoadingStatus())
            return;

          setLoadingStatus(true);

          _this.find('.alert').hide();

          var $form = $(e.target);
          var data = $form.serialize();
          var url = $form.data('action');

          $.ajax({
            url : url,
            data : data,
            method: 'post',
            dataType : 'json',
            success: function(r) {
              if(r.status == "success") {
                _this.find('.alert-success').show();
                _this.find('.buttons').hide();
              }
              if(r.status == "error") {
                _this.find('.alert--error').show();
              }
              if(r.status == "mandatory") {
                _this.find('.alert--mandatory').show();
              }
              setLoadingStatus(false);
            }
          });
      });
    }

    function setLoadingStatus(status) {
      _this.loading = status;
      var $btn = _this.find('button[type="submit"]');
      if(status) {
        $btn.addClass('btn-disabled').attr('disabled', 'disabled');
      } else {
        $btn.removeClass('btn-disabled').removeAttr('disabled');
      }
    }

    function getLoadingStatus() {
      return _this.loading;
    }

    init();
    return this;
  };

  BEHAVIOUR.gallery = function() {

    var _this = $(this);

    function init() {

      if (_this.data('__gallery'))
        return;
      _this.data('__gallery',true);

      _this.galleryFull = new Swiper(_this.find('.gallery__full'), {
        spaceBetween: 10,
        slidesPerView: 1,
        onSlideChangeStart: function(swiper) {
          _this.find('.gallery__thumbs__slide')
            .removeClass('is-active')
            .eq(swiper.activeIndex)
            .addClass('is-active');
        }

      });

      _this.find('.gallery__thumbs__slide').click(function() {
        var index = $(this).index();
        _this.galleryFull.slideTo(index);
      });

    }

    init();
    return this;
  };

  BEHAVIOUR.headerMessage = function() {

    var _this = $(this);

    function init() {

      if (_this.data('__headerMessage'))
        return;
      _this.data('__headerMessage',true);

      _this.find('.close').click(function() {
        _this.remove();
        $(window).trigger('resize');
      });
    }


    init();
    return this;
  };

  BEHAVIOUR.newsletter = function() {

    // require ajaxchimp

    var _this = $(this);

    function init() {

      if (_this.data('__newsletter'))
        return;
      _this.data('__newsletter',true);

      _this.$alert = _this.find('.alert');

      var language = $('html').attr('lang') ?  $('html').attr('lang') : 'en';

      _this.ajaxChimp({
        language: language,
        callback : callbackFunction
      });

      _this.removeAttr('novalidate');
    }

    function callbackFunction (resp) {
       _this.$alert.removeClass('alert-success alert-danger');
      if (resp.result === 'success') {
        _this.$alert.addClass('alert-success');
        _this.find('[data-field], button[type="submit"]').hide();
        _this.find('button[type="button"]').show();
      } else {
        _this.$alert.addClass('alert-danger');
      }
      _this.$alert.show();
    }


    init();
    return this;
  };

  BEHAVIOUR.newsPreview = function() {

    // require waypoint

    var _this = $(this);

    function init() {

      if (_this.data('__newsPreview'))
        return;
      _this.data('__newsPreview',true);

      if(!App.isDesktop)
        return;

      var $childs = _this.find('.news-preview__col__content > *');

      // entering animation at 30%
      _this.waypoint({
        handler : function(direction) {
          TweenMax.staggerTo($childs, 0.5, {y: 0, opacity: 1, ease: Power2.easeOut, force3D: true}, 0.2);
        },
        offset: "40%"
      });

      // entering animation at 60%
      _this.waypoint({
        handler : function(direction) {
          TweenMax.staggerTo($childs, 0.5, {y: 0, opacity: 1, ease: Power2.easeOut, force3D: true}, 0.2);
        },
        offset: function() {
          return - _this.height() + 200;
        }
      });

      // exit animation on top
      _this.waypoint({
        handler : function(direction) {
          if(direction=="down") {
            TweenMax.staggerTo($childs, 0.5, {y: -30, opacity: 0, ease: Power2.easeOut, force3D: true}, 0.2);
          }
        },
        offset : function() {
          return - _this.height();
        }
      });

      // exit animation on bottom
      _this.waypoint({
        handler : function(direction) {
          if(direction=="up") {
            TweenMax.staggerTo($childs, 0.5, {y: 30, opacity: 0, ease: Power2.easeOut, force3D: true}, 0.2);
          }
        },
        offset : "100%"
      });
    }


    init();
    return this;
  };

  BEHAVIOUR.mainNav = function() {

    var _this = $(this);

    function init() {

      if (_this.data('__mainNav'))
        return;
      _this.data('__mainNav',true);

      // duplicate service nav and put it in mobile nav
      $('.service-nav__links').clone().appendTo($('.main-nav__mobile-nav'));

      // duplicate language nav and put it in mobile nav
      var $languageNav = $('.service-nav__language').clone().addClass('collapse').attr('id', "mobile-language-nav");
      var languageTitle = $languageNav.data("title");

      var $languageMobile = $('<li><a href="#" class="toggler">'+ languageTitle + '<span class="main-nav__item-toggle"><span class="icon-plus"></span><span class="icon-up"></span></span></a></li>');
      $languageMobile.append($languageNav).appendTo($('.main-nav__mobile-nav .service-nav__links'));

      $languageMobile.find('> a').click(function(event) {
        event.preventDefault();
        var $parent = $(this).closest('li');
        var $menu = $parent.find('ul');
        if($menu.hasClass('collapsing'))
          return;
        $parent.toggleClass('is-open');
        $menu.collapse('toggle');
      });

      // setup links
      _this.find('.main-nav__pages-nav > li > a').click(function(event) {
        if($(this).attr('href') == "#") {
          event.preventDefault();
          openSubmenu($(this));
        }
      });

      _this.find('.main-nav__subitem-toggle').click(function() {
        var $parent = $(this).closest('li');
        var $menu = $parent.find('ul');

        if($menu.hasClass('collapsing'))
          return;

        $parent.toggleClass('is-open');
        $menu.collapse('toggle');

        // close others
        $parent
          .siblings()
          .removeClass('is-open')
          .find('ul')
          .collapse('hide');

      });

      _this.on('touchstart click', function(event) {
        // event.stopPropagation();
      });

      $('body').on('touchstart.mainNav click.mainNav', function(event) {
        // resetSubmenus();
      });
    }

    function resetSubmenus($skipMenu, $skipLink) {
      // reset only first level
      _this.find('.main-nav__submenu').not($skipMenu).collapse('hide');
      _this.find('.main-nav__pages-nav > li').not($skipLink).removeClass('is-open');
      _this.removeClass('is-inverted');
    }

    function openSubmenu($element) {
      var $parent = $element.closest('li');
      var $menu = $parent.find('.main-nav__submenu');

      // if(App.isDesktop)
      resetSubmenus($menu, $parent);

      if($menu.hasClass('collapsing'))
          return;

      $parent.toggleClass('is-open');
      $menu.collapse('toggle');

      if($parent.hasClass('is-open')) {
        _this.addClass('is-inverted');
      } else {
        _this.removeClass('is-inverted');
      }
    }

    init();
    return this;
  };

  BEHAVIOUR.map = function() {

    var _this = $(this);


    function init() {

      if (_this.data('__map'))
        return;
      _this.data('__map',true);

      _this.loading = false;

      _this.categories = [];

      _this.dataSet = null;
      _this.dataSearchFullSet = null;
      _this.dataSearchSet = null;
      _this.dataFilterSet = null;

      _this.selectedCategory = null;

      _this.map = null;
      _this.markers = [];
      // _this.markerClusterer = null;
      _this.infowindows = [];

      _this.search = {
        name: null,
        lat: null,
        lng: null,
        formatted_address: null
      };

      if (Number.prototype.toRadians === undefined) {
        Number.prototype.toRadians = function() { return this * Math.PI / 180; };
      }

      preload().done(function() {
        setup();
      });

    }

    function createMarker(item) {
      var contentString = '<div class="map__infowindow">'+
        '<h6 class="card__type">' + item.category.name +'</h6>' +
        '<h1 class="card__title" >' + item.title +'</h1>' +
        '<address class="card__address">' + item.address +'</address>' +
        (item.headline ? '<p class="card__headline">' + item.headline +'</p>' : '') +
        '<p class="card__cta"><a href="'+ item.href + '"><span class="card__cta__icon"><span class="icon-info"></span></span>' + _this.data('info-label') +'</a></p>' +
        '</div>';
      var infowindow = new google.maps.InfoWindow({
        content: contentString
      });
      var size = item.category.id == 3 ? new google.maps.Size(31, 36) : new google.maps.Size(35, 41);
      var icon = {
        url: _this.data('marker-'+item.category.id),
        scaledSize: size
      };
      var marker = new google.maps.Marker({
        position: {lat: item.location.lat, lng: item.location.lng},
        icon: icon,
        map: _this.map
      });
      marker.addListener('click', function() {
        for (var i=0; i< _this.infowindows.length; i++) {
          _this.infowindows[i].close();
        }
        infowindow.open(_this.map, marker);
      });
      _this.markers.push(marker);
      _this.infowindows.push(infowindow);
    }

    // create new marker set based on dataFilterSet
    function createMarkers() {
      clearMarkers();
      var set = _this.dataFilterSet;
      for (i = 0; i < set.length; i++) {
        createMarker(set[i]);
      }
      // _this.markerClusterer = new MarkerClusterer(_this.map, _this.markers, {
      //   minimumClusterSize : 2,
      //   styles : [{
      //     url : _this.data('marker-cluster'),
      //     textColor : '#FFF',
      //     textSize : 14,
      //     width : 30,
      //     height : 30
      //   }]
      // });
    }

    // clear all markers from the map
    function clearMarkers() {
      for (var i = 0; i < _this.markers.length; i++) {
        _this.markers[i].setMap(null);
      }
      _this.markers = [];
      _this.infowindows = [];
      // if (_this.markerClusterer)
      //   _this.markerClusterer.clearMarkers();
    }


    function createMap() {
      var mapContainer = _this.find('.map__gmap')[0];
      _this.map = new google.maps.Map(mapContainer, {
        center: {lat: -34.397, lng: 150.644},
        zoom: 8,
        minZoom: 2,
        styles: [{"featureType": "administrative", "elementType": "all", "stylers": [{"visibility": "on"}, {"lightness": 33 } ] }, {"featureType": "landscape", "elementType": "all", "stylers": [{"color": "#efefef"} ] }, {"featureType": "poi", "elementType": "all", "stylers": [{"visibility": "off"} ] }, {"featureType": "poi.park", "elementType": "geometry", "stylers": [{"color": "#e3eed3"}, {"visibility": "off"} ] }, {"featureType": "poi.park", "elementType": "labels", "stylers": [{"visibility": "off"}, {"lightness": 20 } ] }, {"featureType": "road", "elementType": "all", "stylers": [{"lightness": 20 }, {"visibility": "simplified"} ] }, {"featureType": "road.highway", "elementType": "geometry", "stylers": [{"color": "#83a5b0"}, {"visibility": "simplified"} ] }, {"featureType": "road.arterial", "elementType": "geometry", "stylers": [{"color": "#bdcdd3"} ] }, {"featureType": "road.local", "elementType": "geometry", "stylers": [{"color": "#ffffff"} ] }, {"featureType": "water", "elementType": "all", "stylers": [{"visibility": "on"}, {"color": "#b5cbe4"} ] } ],
        scrollwheel: false,
        // draggable: false,
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: true,
        zoomControlOptions: {
          position: google.maps.ControlPosition.RIGHT_CENTER
        },
      });
    }

    function createCards() {
      var set = _this.dataFilterSet;
      var html = "";
      // create cards based on categories order
      // create category 1 first, then all the others
      for (var i = 0; i < set.length; i++) {
        if(set[i].category.id == 1)
          html += getItemHTML(set[i]);
      }
      for (i = 0; i < set.length; i++) {
        if(set[i].category.id != 1)
          html += getItemHTML(set[i]);
      }

      _this.find('.map__card-result .row').html(html);
      setTimeout(function() {
        _this.find('.map__card-result .is-appended').removeClass('is-appended');
      }, 100);
    }

    function createFooterCards() {
      var $footer = _this.find('.map__card-footer');
      // if cardList contain any category=1 card
      var isCategoryShown = false;
      for (var i=0; i < _this.dataSearchSet.length; i++) {
        if(_this.dataFilterSet[i].category.id==1) {
          isCategoryShown = true;
          break;
        }
      }
      if(isCategoryShown) {
        $footer.addClass('hidden');
      } else {
        var set = _this.dataSearchFullSet;
        var html = "";
        for (i = 0; i < set.length; i++) {
          if(set[i].category.id == 1)
            html += getItemHTML(set[i]);
        }
        $footer.find('.row').html(html);
        setTimeout(function() {
          $footer.find('.is-appended').removeClass('is-appended');
        }, 100);
        $footer.removeClass('hidden');
      }
    }

    function createSearchResult() {
      var orderedData = orderByDistances(_this.dataSet);
      _this.dataSearchFullSet = $.extend(true, [], orderedData);
      // reduce the result to the first 10 items
      orderedData.splice(9);
      _this.dataSearchSet = $.extend(true, [], orderedData);
      _this.dataFilterSet = $.extend(true, [], orderedData);
      _this.selectedCategory = undefined;
      updateUI();
    }

    function filterResult(dataId) {
      _this.selectedCategory = (dataId=="all") ? undefined : dataId;
      if(dataId!="all") {
        _this.dataFilterSet = _this.dataSearchSet.filter(function(element) {
          return element.category.id == dataId;
        });
      } else {
        _this.dataFilterSet = _this.dataSearchSet.slice(0);
      }
      updateUI();
    }

    // fit map based on markers and build the marker cluster
    function fitMap() {
      var bounds = new google.maps.LatLngBounds();
      for (var i = 0; i < _this.markers.length; i++) {
        bounds.extend(_this.markers[i].getPosition());
      }
      _this.map.fitBounds(bounds);
    }

    function getDistance(from, to) {
      var lat1 = from.lat;
      var lon1 = from.lng;
      var lat2 = to.lat;
      var lon2 = to.lng;
      // http://www.movable-type.co.uk/scripts/latlong.html
      var R = 6371e3;
      var φ1 = lat1.toRadians();
      var φ2 = lat2.toRadians();
      var Δφ = (lat2-lat1).toRadians();
      var Δλ = (lon2-lon1).toRadians();
      var a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
              Math.cos(φ1) * Math.cos(φ2) *
              Math.sin(Δλ/2) * Math.sin(Δλ/2);
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
      var d = R * c;
      return d;
    }

    function getItemHTML(data) {
      var html = '<article class="card card--category-'+ data.category.id + ' card--badge-' + data.category.id + ' is-appended">';
      html += '<a href="' + data.href + '">';
      if(data.image) {
        html += '<div class="card__image">';
        html += '<div class="card__image__container lazyload" data-bgset="' + data.image +'" data-sizes="auto"></div>';
        html += '</div>';
      }
      html += '<div class="card__content">';
      html += '<h6 class="card__type">' + data.category.name + '</h6>';
      html += '<h1 class="card__title">' + data.title + '</h1>';
      html += '<address class="card__address">' + data.address + '</address>';
      html += data.headline ? '<p class="card__headline">' + data.headline + '</p>' : '';
      html += '<p class="card__cta"><span class="card__cta__icon"><span class="icon-info"></span></span>'+ _this.data('info-label') +'</p>';
      if(data.distance) {
        html += '<p class="card__distance">' + parseInt(data.distance/100)/10 + ' Km</p>';
      }
      html += '<div class="card__badge"></div>';
      html += '</div>';
      html += '</a>';
      html += '</article>';
      return html;
    }

    function getPlaceDetail(place) {
      var deferred = new $.Deferred();
      var service = new google.maps.places.PlacesService(document.getElementById('PlacesService'));
      service.getDetails({reference: place.reference}, function(results, status) {
        deferred.resolve(results);
      });
      return deferred.promise();
    }

    function getPlacePredictions(text) {
      var deferred = new $.Deferred();
      var service = new google.maps.places.AutocompleteService();
      service.getPlacePredictions({input: text, types: ['(cities)']}, function(results, status) {
          deferred.resolve(results);
      });
      return deferred.promise();
    }

    // load gmaps api
    function loadGmaps() {
      var deferred = new $.Deferred();
      if(!window.gMapsLoaded) {
        window.initMap = function() {
          window.gMapsLoaded = true;
                    deferred.resolve();
                  };
        loadScript('//maps.googleapis.com/maps/api/js?v=3&callback=initMap&libraries=places&key=' + _this.data('api-key'));
      } else {
        deferred.resolve();
      }
      return deferred.promise();
    }

    // load locations json specified in data-locations
    function loadLocations() {
      var deferred = new $.Deferred();
      var url = _this.data('locations');
      $.ajax({
        url : url,
        dataType: 'json',
        success: function(r) {
          deferred.resolve(r);
        }
      });
      return deferred.promise();
    }

    // utility to load script in the head
    function loadScript(src,callback) {
      var script = document.createElement("script");
      script.type = "text/javascript";
      document.getElementsByTagName("head")[0].appendChild(script);
      script.src = src;
    }

    // return a dataSet ordered by distance based on _this.search values
    function orderByDistances(set) {

      var orderedData = $.extend(true, [], set);

      var length = orderedData.length;

      var to = {
        lat: Number(_this.search.lat),
        lng: Number(_this.search.lng)
      };

      for (var i = 0; i < length; i++) {
        var item = orderedData[i];
        var from = {
          lat: Number(item.location.lat),
          lng: Number(item.location.lng)
        };
        item.distance = getDistance(from, to);
        orderedData[i] = item;
      }

      // return orderedLocations;
      // console.log(orderedLocations);
      orderedData.sort(function(a, b) {
        if (a.distance > b.distance) {
          return 1;
        }
        if (a.distance < b.distance) {
          return -1;
        }
        // a equal to b
        return 0;
      });

      return orderedData;
    }

    // preload the needed components
    function preload() {
      var deferred = new $.Deferred();
      loadLocations().done(function(result) {
        _this.categories = result.categories;
        _this.dataSet = $.extend(true, [], result.locations);
        _this.dataSearchSet = $.extend(true, [], result.locations);
        _this.dataSearchFullSet = $.extend(true, [], result.locations);
        _this.dataFilterSet = $.extend(true, [], result.locations);
        loadGmaps().done(function() {
          deferred.resolve();
        });
      });
      return deferred.promise();
    }

    // reset search set and show default
    function resetSearch() {
      _this.search.name = undefined;
      _this.search.lat = undefined;
      _this.search.lng = undefined;
      _this.search.formatted_address = undefined;
      _this.dataSearchSet = $.extend(true, [], _this.dataSet);
      _this.dataSearchFullSet = $.extend(true, [], _this.dataSet);
      _this.dataFilterSet = $.extend(true, [], _this.dataSet);
      _this.selectedCategory = undefined;
      _this.find('.map__form input').val('');
      _this.removeClass('has-search');
      updateUI();
    }

    // save founded location for future reference and avoid useless api calls
    function saveSearch(search) {
      if(search.name == _this.search.name) {
        // console.log('SEARCH NOT SAVED');
      } else {
        _this.search = search;
        createSearchResult();
        _this.addClass('has-search');
      }
    }

    // setup the UI
    function setup() {

      // setup google maps autocomplete
      var input = _this.find('input[name="search"]')[0];
      var options = {
        types: ['(cities)']
      };
      var autocomplete = new google.maps.places.Autocomplete(input, options);
      google.maps.event.addListener(autocomplete, 'place_changed', function() {
        var place = autocomplete.getPlace();
        if (place.geometry && place.geometry.location) {
          var search = {
            name : _this.find('input[name="search"]').val(),
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
            formatted_address: place.formatted_address
          };
          saveSearch(search);
        }
      });

      // setup form
      _this.find('.map__form form').submit(function(event) {
        event.preventDefault();
        var $this = $(this);
        setTimeout(function() {
          var text = $this.find('input').val();
          if(text && text!=_this.search.name) {
            getPlacePredictions(text).done(function(r) {
              if(r) {
                var place = r[0];
                getPlaceDetail(place).done(function(r) {
                  if (r.geometry && r.geometry.location) {
                    var search = {
                      name : text,
                      lat: r.geometry.location.lat(),
                      lng: r.geometry.location.lng(),
                      formatted_address: r.formatted_address
                    };
                    saveSearch(search);
                  }
                  $(input).val(r.formatted_address);
                });
              } else {
                showNotFound(text);
              }
            });
          } else {
            // console.log('search submit skipped');
          }
        }, 300);
      });

      // createFilters
      var $filters = _this.find('.map__filters ul');
      $.each(_this.categories, function() {
        var filter = '<li><a href="#" data-id="' + this.id + '">' + this.name + '</a></li>';
        _this.find('.map__filters ul').append(filter);
      });
      _this.find('.map__filters li a').click(function(event) {
        event.preventDefault();
        var $this = $(this);
        if(!$this.hasClass('is-disabled'))
          filterResult($this.data('id'));
      });

      // create gmap
      createMap();

      updateUI();

      // reset search button
      $('.map__form__reset').click(function(event) {
        event.preventDefault();
        resetSearch();
      });
    }



    // show modal for search error
    function showNotFound(s) {
      var $modal = $('#modal-map-error');
      $modal.modal('show');
      setTimeout(function() {
        $modal.find('.modal-footer button').focus();
      }, 500);
    }

    // function getGeocode(search) {
    //   console.log('geocoding for: ' + search);
    //   var deferred = new $.Deferred();
    //   var geocoder = new google.maps.Geocoder();
    //   geocoder.geocode({address: search}, function(results, status) {
    //     deferred.resolve(results);
    //   });
    //   return deferred.promise();
    // }

    function setLoadingStatus(status) {
      _this.loading = status;
    }

    function getLoadingStatus() {
      return _this.loading;
    }

    function updateFilterNav() {
      var $filters = _this.find('.map__filters a');
      $filters.removeClass('is-active').removeClass('is-disabled');
      var dataId = _this.selectedCategory ? _this.selectedCategory : "all";
      $filters.filter('[data-id="' + dataId + '"]').addClass('is-active');

      // find disabled categories
      var cardCategories = [];
      for (var i=0; i < _this.dataSearchSet.length; i++) {
        cardCategories.push(_this.dataSearchSet[i].category.id);
      }
      cardCategories = cardCategories.filter(function(value, index, self) {
        return self.indexOf(value) === index;
      });
      $filters.each(function() {
        var $this = $(this);
        var id = String($this.data('id'));
        if(id !="all" && cardCategories.indexOf(id) < 0)
          $this.addClass('is-disabled');
      });

    }

    function updateUI() {
      updateFilterNav();
      createMarkers();
      fitMap();
      createCards();
      createFooterCards();
    }


    init();
    return this;
  };

  BEHAVIOUR.modalCookie = function() {

    // require js-cookies

    var _this = $(this);

    function init() {

      if (_this.data('__modalCookie'))
        return;
      _this.data('__modalCookie',true);

      collectModals();
      checkCookies();

    }

    function collectModals() {
      _this.modals = [];

      _this.find('.modal').each(function() {
        var $el = $(this);
        var cookieName = $el.data('cookie-name');
        var priority = parseInt($el.data('priority'));

        if(!Number.isInteger(priority))
          priority = 0;

        _this.modals.push({
          dom : $el,
          cookieName : cookieName,
          priority: priority
        });
      });

      // order modals by priority
      _this.modals = _this.modals.sort(function(a,b) {
        return b.priority - a.priority;
      });

    }

    function checkCookies() {
      $.each(_this.modals, function() {
        if(this.cookieName != undefined && Cookies.get(this.cookieName) != 1) {
          showModal(this);
          return false;
        }
      });
    }

    function showModal(modal) {
      setTimeout(function() {
        // check if any modal is open, otherwise skip the opening.
        if($('.modal.in').length===0)

          modal.dom.modal('show');

          modal.dom.find('[data-dismiss="modal"]').click(function() {
            setCookie(modal.cookieName);
          });

          modal.dom.find('a').click(function(event) {
            var href = $(this).attr('href');
            var target = $(this).attr('target')
            if(target != "_blank") {
              setCookie(modal.cookieName);
              event.preventDefault();
              setTimeout(function() {
                document.location.href= href;
              }, 300);
            }
          });

      }, 3 * 1000);
    }

    function setCookie(cookieName) {
      Cookies.set(cookieName, '1', { expires: 30*3 });
    }

    init();
    return this;
  };

  BEHAVIOUR.modalEvents = function() {

    var _this = $(this);

    function init() {

      if (_this.data('__modalEvents'))
        return;
      _this.data('__modalEvents',true);

      $('.modal').each(function() {

        var $this = $(this);

        $this.on('show.bs.modal', function(e) {
          centerModal($(this));
          $(window).bind('resize.modal', function() {
            centerModal($(this));
          });
        });

        $this.on('hidden.bs.modal', function (e) {
          $(window).unbind('resize.modal');
        });

      });
    }

    function centerModal($modal) {
      var $clone = $modal.clone().css('display', 'block').appendTo('body');
      var top = Math.round(($clone.height() - $clone.find('.modal-content').height()) / 2);
      top = top > 0 ? top : 0;
      top = top > 100 ? top-30 : top;
      $clone.remove();
      $modal.find('.modal-content').css("margin-top", top);
    }

    init();
    return this;
  };
  GLOBAL_BEHAVIOURS.push("modalEvents");

  BEHAVIOUR.newCustomerForm = function() {

    // require js-cookies

    var _this = $(this);

    function init() {

      if (_this.data('__newCustomForm'))
        return;
      _this.data('__newCustomForm',true);

      var url = _this.data('url');

      // _this.off('submit');

      _this.on('submit', function(event) {

        _this.find('.alert').remove();

        event.stopPropagation();
        event.preventDefault();

        var error = false;
        var $email = _this.find('input[name="popup_email"]');
        var $privacy = _this.find('input[name="popup_privacy"]');

        $email.closest('div').removeClass('has-error');
        $privacy.closest('div').removeClass('has-error');

        filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

        if (!filter.test($email.val())) {
          error = true;
          $email.closest('div').addClass('has-error');
        }

        if (!$privacy.is(':checked')) {
          error = true;
          $privacy.closest('div').addClass('has-error');
        }

        if (!error) {
          $.ajax({
            url: url,
            data: _this.serialize(),
            method: 'get',
            dataType: 'json',
            success: function(r) {
              if(r.success) {
                _this.find('.form-content, .cta').remove();
                _this.find('.modal-body').append('<p class="response">' + r.message + '</p><p class="coupon">' + r.coupon + '</p>');
                setCookie();
              } else {
                _this.find('.form-content').prepend('<div class="alert alert-danger">' + r.message + '</div>');
              }
            }
          });
        }

      });

    }

    function setCookie() {
      var cookieName = _this.closest('.modal').data('cookie-name');
      if(cookieName) {
        Cookies.set(cookieName, '1', { expires: 30*3 });
      }
    }

    init();
    return this;
  };

  BEHAVIOUR.productDetail = function() {

    var _this = $(this);

    function init() {

      if (_this.data('__productDetail'))
        return;
      _this.data('__productDetail',true);

      _this.loading = false;

      afterInit();

    }

    function afterInit() {

      _this.find('select').change(function() {
        var $this = $(this);
        var value = $this.val();
        var $option = $this.find('option[value="'+value+'"]');
        var $price = _this.find('.product-detail__price [data-price]');
        var $priceReduced = _this.find('.product-detail__price [data-price-reduced]');

        if($option.data('price'))
          $price.text($option.data('price'));

        if($option.data('price-reduced')) {
          $priceReduced.text($option.data('price-reduced')).removeClass('hidden');
        } else {
          $priceReduced.addClass('hidden');
        }
      });

      _this.find('.product-detail__colors__list a').click(function(event) {
        event.preventDefault();

        if(_this.loading)
          return;

        _this.loading = true;
        _this.addClass('block-ui');

        $.ajax({
          url : $(this).attr('href'),
          success: function(r) {
            var $html = $('<div></div>').append(r);
            _this.empty();
            _this.html($html.find('.product-detail').html());
            _this.GUI();
            afterInit();
            _this.removeClass('block-ui');
            _this.loading = false;
          }
        });

      });

    }

    init();
    return this;
  };

  BEHAVIOUR.scrollTo = function() {

    var _this = $(this);

    function init() {

      if (_this.data('__scrollTo'))
        return;
      _this.data('__scrollTo',true);

      var target = _this.data('target');
      var offset = _this.data('offset');

      if(!offset)
        offset = 0;

      _this.click(function(event) {
        event.preventDefault();
        TweenLite.to(window, 0.4, {scrollTo: {y:_this.data('target'), offsetY:offset }});
      });

    }


    init();
    return this;
  };

  BEHAVIOUR.stepper = function() {

    var _this = $(this);

    function init() {

      if (_this.data('__stepper'))
        return;
      _this.data('__stepper',true);

      _this.$input = _this.find('input');
      _this.$minus = _this.find('.stepper__minus');
      _this.$plus = _this.find('.stepper__plus');

      _this.$input.on('change', function() {
        setValue($(this).val());
      });

      _this.$minus.click(function() {
        modifyValue(-1);
      });

      _this.$plus.click(function() {
        modifyValue(1);
      });

    }

    function getValue() {
      return fixValue(_this.find('input').val());
    }

    function setValue(value) {
      _this.find('input').val(fixValue(value));
    }

    function modifyValue(amount) {
      var value = getValue();
      setValue(value+amount);
    }

    function fixValue(value) {
      value = parseInt(value);
      if(value===0 || isNaN(value))
        value=1;
      return value;
    }

    init();
    return this;
  };

  BEHAVIOUR.select = function() {

    var _this = $(this);

    function init() {

      if (_this.data('__select'))
        return;
      _this.data('__select',true);

      _this.$select = _this.find('select');
      _this.$label = _this.find('.fancy-select__label');

      updateValue();

      _this.$select.change(function() {
        updateValue();
      });

    }

    function updateValue() {
      var value = _this.$select.val();
      var $option = _this.$select.find('option[value="' + value + '"]');
      _this.$label.text($option.text());
    }

    init();
    return this;
  };

  BEHAVIOUR.stripe = function() {

    var _this = $(this);

    function init() {

      if (_this.data('__stripe'))
        return;
      _this.data('__stripe',true);

      _this.waypoint({
        handler : function() {
          var $this = $(this.element);
          $childs = $this.find('.stripe__column > *');
          if($childs.first().css('opacity') == "0")
            TweenMax.staggerFromTo($childs, 0.5, {y: 30, opacity: 0}, {y: 0, opacity: 1, ease: Power2.easeOut, force3D: true}, 0.2);
          this.destroy();
        },
        offset: "60%"
      });

    }

    init();
    return this;
  };

  BEHAVIOUR.toolNav = function() {

    // require waypoints

    var _this = $(this);

    function init() {

      if (_this.data('__toolNav'))
        return;
      _this.data('__toolNav',true);

      _this.waypoint({
        handler: function(direction) {
          if(direction=="down") {
            $(this.element).addClass('is-sticky');
          } else {
            $(this.element).removeClass('is-sticky');
          }
        }
      });

    }

    init();
    return this;
  };

  BEHAVIOUR.share = function() {

      // data-share-dialog: window|popup optional
      // data-url: optional
      // data-title: optional
      // data-description: optional
      // data-source: optional


    var _this = $(this);

    function init() {

      if (_this.data('__share'))
        return;
      _this.data('__share',true);

      _this.data('share-dialog', 'popup');

      _this.dialog = (_this.data('share-dialog') === undefined) ? 'window' : _this.data('share-dialog');

      _this._url = (_this.data('url') === undefined) ? location.href : _this.data('url');
      //_this.url = location.protocol + '//' + location.host + $('[data-role="page"].ui-page-active').jqmData('url');
      _this._title = (_this.data('title') === undefined) ? $('title').text() : _this.data('title');

      _this.find('.facebook').click(facebook);
      _this.find('.twitter').click(twitter);
      _this.find('.google-plus').click(gplus);
      _this.find('.linkedin').click(linkedin);
      _this.find('.email').click(email);
    }

    function facebook(event) {
      event.preventDefault();
      openPopup('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(_this._url), 'facebook-share-dialog', 550, 450);
    }

    function twitter(event) {
      event.preventDefault();
      openPopup('https://twitter.com/intent/tweet?url=' + encodeURIComponent(_this._url) + '&text=' + encodeURIComponent(_this._title), 'twitter-share-dialog', 550, 450);
    }

    function gplus(event) {
      event.preventDefault();
      openPopup('https://plus.google.com/share?url=' + encodeURIComponent(_this._url), 'facebook-share-dialog', 550, 450);
    }

    function linkedin(event) {
      event.preventDefault();
      openPopup('https://www.linkedin.com/shareArticle?url=' + encodeURIComponent(_this._url), 'linkedin-share-dialog', 550, 450);
    }

    function email(event) {
      event.preventDefault();
      var emailTo = '',
        subject = _this._title,
        body = _this._title + "\n\n" + _this._url + "\n\n";
      location.href = 'mailto:' + emailTo + "?Subject=" + encodeURIComponent(subject) + "&Body=" + encodeURIComponent(body);
    }

    function openPopup(url, title, w, h) {
      if (_this.dialog == "popup") {
        var left = (screen.width / 2) - (w / 2), top = (screen.height / 2) - (h / 2);
        return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
      } else {
        return window.open(url);
      }
    }

    init();
    return this;
  };

  $.fn.GUI = function()
  {
    $.each(BEHAVIOUR, function(i,v) {
      var name = BEHAVIOUR_PREFIX + i;
      if(!$.isFunction($.fn[name]))
        $.fn[name] = v;
      $("." + BEHAVIOUR_PREFIX + "-" + i).each(function() {
        $(this)[name]();
      });
    });
    if($.isArray(GLOBAL_BEHAVIOURS)) {
      $.each(GLOBAL_BEHAVIOURS, function(i,v) {
        $('html')[BEHAVIOUR_PREFIX + v]();
      });
    }
    return this;
  };

  $(document).ready(function()
  {
    $(document).ready(function(){
        $('[data-toggle="tooltip"]').tooltip();
    });
    $('html').GUI();
  });


  $(window).load(function()
  {
    $.windowLoaded = true;
  });

})(jQuery, window);
